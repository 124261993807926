import { authCodeFlowConfig } from './auth.config';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ApmService } from '@elastic/apm-rum-angular';
import { environment } from '@environments/environment';
import { BrowserCheckService } from '@global/services/browser-check.service';
import { LanguageService } from '@global/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { FreshchatService } from '@shared/services/freshchat.service';
import { FeatureToggleService } from '@global/feature-toggle/feature-toggle.service';
import { Theme } from '@shared/theme.enum.';
import { ThemesService } from '@shared/themes.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { DeviceInfo } from 'ngx-device-detector';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { forkJoin } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import mixpanel from 'mixpanel-browser';
import { FeatureToggle } from '@global/feature-toggle/feature-toggle.model';

@Component({
  selector: 'oes-app-root',
  templateUrl: './app.component.html',
  // eslint-disable-next-line
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) public reloadDialog: ModalDialogComponent;

  allThemes = Object.values(Theme);
  currentTheme = Theme.ODYSSEY;
  deviceInfo: DeviceInfo;
  environment: string;
  featureToggleData: any;
  formGroup: UntypedFormGroup;
  isMobileOrTablet = false;
  languages = [
    {name: 'English', abbr: 'en'},
    {name: 'Français', abbr: 'fr'},
    {name: 'Português', abbr: 'pt'}
  ];
  progressRef: NgProgressRef;
  renderOapHome: boolean = false;

  private apm: any;

  constructor(private _translateService: TranslateService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _themesService: ThemesService,
              private _browserCheckService: BrowserCheckService,
              private _userService: UserService,
              private _languageService: LanguageService,
              private _freshchatService: FreshchatService,
              private _swUpdate: SwUpdate,
              private _titleService: Title,
              private _ngProgress: NgProgress,
              private oauthService: OAuthService,
              private _apmService: ApmService,
              private _featureToggleService: FeatureToggleService) {
    this.progressRef = this._ngProgress.ref();
    // NOTE: this language will be used as a fallback when a translation isn't found in the current language
    _translateService.setDefaultLang('en');
    // NOTE: the lang to use, if the lang isn't available, it will use the current loader to get them
    // NOTE: for debugging toggle the below comments
    _translateService.use(this._languageService.getLanguage());

    this.environment = `oes-${environment.name}`;
    switch (this.environment) {
      case 'oes-development':
      case 'oes-dev':
      case 'oes-cloud':
      case 'oes-qa':
      case 'oes-ecrqa':
      case 'oes-reaqa':
        break;
    }

    this.deviceInfo = this._browserCheckService.deviceInfo;
    this.configureTheme();

    this.getAndProcessLoginStatus();

    this.oauthService.events
      .pipe(filter(e => {
        return e.type === 'invalid_nonce_in_state' || e.type === 'code_error';
      }))
      .subscribe(_event => {
        if (_event.type === 'invalid_nonce_in_state') {
          this.getAndProcessLoginStatus();
        }
        if (_event.type === 'code_error') {
          window.location.href = window.location.origin;
        }
      });
  }

  private getAndProcessLoginStatus() {
    this.getLoginStatus().then(isLoggedIn => {
      if (isLoggedIn) {
        const getUserPromise = this._userService.getCurrentUser();
        const getFeatureTogglePromise = this.loadFeatureToggles();
        forkJoin([getUserPromise, getFeatureTogglePromise]).subscribe(
          (results) =>
            this.afterAllContextInfoRetrieved(results[0], results[1])
        );
        this.oauthService.setupAutomaticSilentRefresh();
      } else if (window.location.hash) {
        localStorage.setItem('hashBeforeLogin', window.location.hash);
      }
    });
  }

  private afterAllContextInfoRetrieved(user: User, featureToggleResponse: FeatureToggle[]) {
    this._featureToggleService.load(featureToggleResponse);

    if (this._featureToggleService.isFeatureEnabled('elastic-apm')) {
      this.apmInit();
      this.apmAddUser(user);
    }

    if (this._featureToggleService.isFeatureEnabled('mixpanel')) {
      this.initMixPanel(user);
    }

    if (user.organization.oapEnabled) {
      this.initOapNavigation(user);
    }

    this.navigateToInitialPage();
  }

  get userName(): string {
    const idToken = this.oauthService.getIdToken();
    if (!idToken) {
      return null;
    }
    return idToken['preferred_username'];
  }

  get idToken(): string {
    return this.oauthService.getIdToken();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  private loadFeatureToggles() {
    return this._featureToggleService.list().toPromise();
  }

  private navigateToInitialPage() {
    const hashBeforeLogin = localStorage.getItem('hashBeforeLogin');
    localStorage.removeItem('hashBeforeLogin');
    this._router.navigateByUrl((hashBeforeLogin || window.location.hash).replace('#', ''));
  }

  private initOapNavigation(user: User) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/oes/home') {
          this.renderOapHome = true;
          window.location.href = environment.oapHomeUrl;
        }
      }
    });
  }

  // needed by ngx's 'feature-toggle-provider' component
  getFeatureToggles() {
    return this._featureToggleService.getFeatureTogglesAsObject();
  }

  ngOnInit() {
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(_ => {
        this.oauthService.loadUserProfile().then(() => {
          console.log(`${window.location.hostname}, ${environment.version}, ${this._languageService.getLanguage()}`);
          if (environment.name === 'dev') {
            this.logTokens();
          }
        });
      });
  }

  getLoginStatus() {
    authCodeFlowConfig.clientId = this._themesService.getAuthClientId();
    this.oauthService.configure(authCodeFlowConfig);
    return this.oauthService.loadDiscoveryDocumentAndLogin({
      disableOAuth2StateCheck: true
    });
  }

  refresh() {
    this.oauthService.refreshToken();
  }

  reload() {
    window.location.reload();
  }

  private apmInit() {
    this.apm = this._apmService.init({
      serviceName: 'oes-dt-ui',
      serverUrl: environment.apmServerUrl
    });
  }

  private apmAddUser(user: User) {
    this.apm?.setUserContext({
      'id': user.id,
      'username': `${user.firstName} ${user.lastName}`,
      'email': user.username
    });
  }

  private initMixPanel(user: User) {
    const mixpanelProjectId = environment.mixpanel.projectId;
    if (!mixpanelProjectId) {
      console.log('MixPanel feature is enabled but no project ID found in environment. Mixpanel events will NOT be sent.');
      return;
    }
    mixpanel.init(mixpanelProjectId, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
      ignore_dnt: true
    });

    mixpanel.identify(user.username);

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const rawPageUrl = event.urlAfterRedirects;
        const cleanedUrlAndIds = this.cleanAndExtractIdsFromUrl(rawPageUrl);
        const currentRole = this._userService.getSelectedRole();
        mixpanel.track_pageview({
          'raw_page_url': rawPageUrl,
          'cleaned_page_url': cleanedUrlAndIds.cleanedUrl,
          'ids_in_url': cleanedUrlAndIds.ids,
          'oes_role': this._userService.getSelectedRole()
        });
      }
    });
  }

  private cleanAndExtractIdsFromUrl(url: string) {
    const uuidRegex = /[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}/g;

    // Extract the UUID from the URL
    const extractedIds = url.match(uuidRegex);

    if (extractedIds) {
      let cleanedUrl = url;
      extractedIds.forEach(extractedId => {
        cleanedUrl = cleanedUrl.replace(extractedId, 'UNIQUE_ID');
      });
      return {
        cleanedUrl: cleanedUrl,
        ids: extractedIds
      };
    }

    // Return the original URL if no UUID found
    return {
      cleanedUrl: url
    };
  }

  private logTokens() {
    const idToken = jwt_decode(this.oauthService.getIdToken()); // has locale but no local until user select a language
    const accessToken = jwt_decode(this.oauthService.getAccessToken()); // has roles and groups
    const refreshToken = jwt_decode(this.oauthService.getRefreshToken());
  }

  private configureTheme() {
    this.formGroup = new UntypedFormGroup({
      themeSelector: new UntypedFormControl()
    });
    this.formGroup.controls['themeSelector'].valueChanges
      .subscribe(value => {
        if (value && value !== '') {
          this.currentTheme = value;
          this._themesService.setTheme(value);
        }
      });
    this.swUpdateCheck();
    this.checkForRedirect();
    this.currentTheme = this._themesService.getTheme();
    this._themesService.setTheme(this.currentTheme);
    if (environment.name === 'prod' || environment.name === 'uat') {
      this._freshchatService.addScript();
    }
    this.getTitle();
  }

  private checkForRedirect() {
    if (location.host === 'rearef.odysseyenergysolutions.com') {
      window.location.href = 'https://reamas.odysseyenergysolutions.com';
    }
    if (location.host === 'oes-dt-ui-rearef-qa.cfapps.io') {
      window.location.href = 'https://oes-dt-ui-reamas-qa.cfapps.io';
    }
  }

  private swUpdateCheck() {
    this._swUpdate.versionUpdates
      .pipe(take(1))
      .subscribe((event) => {
        if (event.type === 'VERSION_READY') {
              window.location.reload();
        }
      });
  }

  private getTitle() {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this._activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['breadcrumb']) {
            return child.snapshot.data['breadcrumb'];
          }
          return '';
        })
      ).subscribe((ttl: string) => {
      if (ttl) {
        const translated = this._translateService.instant(ttl);
        this._titleService.setTitle(translated + ' - Odyssey');
      } else {
        this._titleService.setTitle('Odyssey');
      }
    });
  }

}
