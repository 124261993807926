import {Injectable} from '@angular/core';
import { ColDef, GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import {BaseAgGridSettingsService} from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { GridState } from '@shared/ag-grid/gird-state';

@Injectable({
  providedIn: 'root'
})
export class DataManagerGridSettingService extends BaseAgGridSettingsService {
  public columnValues = [];
  public operatorValues = [];
  private column = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    _agGridServicesWrapperService._translateService.get('import-data.column').subscribe(column => {
      this.column = column;
    });
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(),
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      suppressRowClickSelection: false,
      rowMultiSelectWithClick: true,
      groupHideOpenParents: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      suppressDragLeaveHidesColumns: false,
      rowGroupPanelShow: 'always',
      rowHeight: 80,
      enableRangeSelection: true,
      suppressSizeToFit: false,
      autoGroupColumnDef: {
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true
        }
      },
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading Data Import Requests...</span>',
    };
  }

  // Column Properties
  // https://www.ag-grid.com/javascript-grid-column-properties/
  public getColumnSetting(): ColDef[] {
    return [
      <ColDef>{
        headerName: this.column['requestItemType'],
        headerTooltip: this.column['requestItemType'],
        field: 'requestItemType',
        width: 220,
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        resizable: true
      },
      <ColDef>{
        headerName: this.column['status'],
        headerTooltip: this.column['status'],
        field: 'status',
        width: 100,
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        resizable: true
      },
      <ColDef>{
        headerName: this.column['message'],
        headerTooltip: this.column['message'],
        field: 'data',
        valueGetter: (params) => {
          return params?.data?.data && this.createMessage(params.data.data);
        },
        width: 375,
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        resizable: true
      },
      <ColDef>{
        headerName: this.column['file-id'],
        width: 300,
        field: 'dataImportRequest',
        rowGroup: true,
        hide: true,
        valueGetter: (item) => item?.data?.dataImportRequest && `${item.data.dataImportRequest.status}: ${item.data.dataImportRequest.created}`,
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        resizable: true
      },
      <ColDef>{
        headerName: this.column['created'],
        headerTooltip: this.column['created'],
        field: 'created',
        width: 220,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        resizable: true
      }
    ];
  }
  private createMessage(importRequestItem: string) {
    const cleanString = importRequestItem.replace(/\"\"/g, '');
    const requestObj = JSON.parse(cleanString);
    const nameOrData = requestObj?.name || requestObj?.username || importRequestItem;
    return (requestObj.message ? requestObj.message + ': ' : '') + nameOrData;
  }
}
