<div class="home" data-testid="uef-home-container">
  <div class="header">
    <img src="/assets/images/uef/uef-homepage.jpg" height="160px">
    <h4>- {{ 'home-uef.tagline' | translate }} -</h4>
  </div>
  <div class="body">
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/uef1s.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/uef2s.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/uef3s.jpg"/>
      <div class="credit">{{ 'home-uef.photos-by' | translate }}</div>
    </div>

    <div class="countries">
      <div class="countries-left">
        <h5>{{ 'home-uef.standalone' | translate }}</h5>
        <div class="country-container">
          <div class="country-display country-display-nigeria">
            <div class="country-heading">
              <h3>Nigeria</h3>
              <ng-container *ngIf="programAvailable.nigeria; else LearnMore">
                <button class="btn btn-small btn-primary" [ngClass]="{ 'button-hidden' : buttonsHidden }" [routerLink]="['../finance/programs/' + prodProgramIds.nigeria + '/home']">{{ 'buttons.apply' | translate }}</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="countries-right">
        <h5>{{ 'home-uef.minigrids' | translate }}</h5>
        <div class="country-container">
          <div class="country-display country-display-benin">
            <div class="country-heading">
              <h3>Benin</h3>
              <ng-container *ngIf="programAvailable.benin; else LearnMore">
                <button class="btn btn-small btn-primary" [ngClass]="{ 'button-hidden' : buttonsHidden }" [routerLink]="['../finance/programs/' + prodProgramIds.benin + '/home']">{{ 'buttons.apply' | translate }}</button>
              </ng-container>
            </div>
          </div>
          <div class="country-display country-display-sierra-leone">
            <div class="country-heading">
              <h3>Sierra Leone</h3>
              <ng-container *ngIf="programAvailable.sierraLeone; else LearnMore">
                <button class="btn btn-small btn-primary" [ngClass]="{ 'button-hidden' : buttonsHidden }" [routerLink]="['../finance/programs/' + prodProgramIds.sierraLeone + '/home']">{{ 'buttons.apply' | translate }}</button>
              </ng-container>
            </div>
          </div>
          <div class="country-display country-display-madagascar">
            <div class="country-heading">
              <h3>Madagascar</h3>
              <ng-container *ngIf="programAvailable.madagascar; else LearnMore">
                <button class="btn btn-small btn-primary" [ngClass]="{ 'button-hidden' : buttonsHidden }" [routerLink]="['../finance/programs/' + prodProgramIds.madagascar + '/home']">{{ 'buttons.apply' | translate }}</button>
              </ng-container>
            </div>
          </div>
          <div class="country-display country-display-drc">
            <div class="country-heading">
              <h3>Democratic Republic of the Congo</h3>
              <ng-container *ngIf="programAvailable.drc; else LearnMore">
                <button class="btn btn-small btn-primary" [ngClass]="{ 'button-hidden' : buttonsHidden }" [routerLink]="['../finance/programs/' + prodProgramIds.drc + '/home']">{{ 'buttons.apply' | translate }}</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #LearnMore>
      <button class="btn btn-small btn-primary" [ngClass]="{ 'button-hidden' : buttonsHidden }"><a href="https://www.seforall.org/results-based-financing" target="_blank">{{ 'buttons.learn-more' | translate }}</a></button>
    </ng-template>

    <h5>{{ 'home-uef.implementing-partners' | translate }}</h5>
    <div class="partners">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/Shell Foundation_SF Logo in blue box.jpg" height="90px"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/the-rockefeller-foundation-logo.jpg" height="90px"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/geapp.png" height="80px"/>
    </div>
    <div class="partners">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/Logos and Images_AMDA square.PNG" height="80px" width="130px" />
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/USAID_USAID_Power_Africa_Logo_Tag_Color_MASTER.jpeg" height="67px"/>
      </div>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/good-energies logo.gif" height="90px"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/UK-AID.gif" height="90px"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/Carbon-Trust_Logo_CMYK.gif" height="90px"/>
    </div>
    <div class="partners">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/ikea-foundation-logo.png" height="51px" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/calogo.jpg" height="90px" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/gizlogo.jpg" height="90px" />
    </div>

    <div class="manager">
      <div class="group">
        <h5>{{ 'home-uef.program-manager' | translate }}</h5>
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/energy for all.png" height="140px"/>
      </div>
      <div class="group">
        <h5>{{ 'home-uef.supported-by' | translate }}</h5>
        <div class="image-wrapper">
          <img src="https://oes-hosted-images-public.s3.amazonaws.com/partners/UN Energy.png" height="90px"/>
        </div>
      </div>
    </div>

    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
