<ng-container *ngIf="!renderOapHome">
  <oes-popup-message></oes-popup-message>
  <ng-progress
    [direction]="'rtl-'"
    [thick]="true"
    [ease]="'easeInSine'">
  </ng-progress>
  <feature-toggle-provider [features]="getFeatureToggles()">
    <div *ngIf="['oes-qa', 'oes-dev'].includes(environment)" [formGroup]="formGroup">
      <select
        class="theme-box"
        formControlName="themeSelector">
        <option *ngFor="let theme of allThemes">{{theme}}</select>
    </div>
    <div class="main-router" [ngClass]="{'no-clicking': progressRef.isStarted}">
      <router-outlet></router-outlet>
    </div>
    <oes-modal-dialog
      [size]="'small'"
      [title]="'login.reload-dialog.title' | translate"
      [allowSubmit]="true"
      [allowCancel]="false"
      [submitButtonText]="'buttons.reload' | translate"
      (close)="reload()"
      (submit)="reload()">
      <div>{{'login.reload-dialog.message' | translate}}</div>
    </oes-modal-dialog>
  </feature-toggle-provider>
</ng-container>
